import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';
import angular from '../../assets/images/technologies/angular.svg'
import node from '../../assets/images/technologies/node.svg'
import react from '../../assets/images/technologies/react.svg'
import html from '../../assets/images/technologies/html.svg'
import sass from '../../assets/images/technologies/sass.svg'
import git from '../../assets/images/technologies/git.svg'

const options = {
    loop: true,
    nav: false,
    dots: false,
    smartSpeed: 2000,
    margin: 30,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='flaticon-back'></i>",
        "<i class='flaticon-right'></i>"
    ],
    responsive: {
        0: {
            items: 2
        },
        576: {
            items: 3
        },
        768: {
            items: 4
        },
        1200: {
            items: 6
        }
    }
}
 
class PartnerLogo extends React.Component {
    render(){
        return (
            <div className="partner-area uk-section uk-padding-remove-top">
                <div className="uk-container">
                    <div className="uk-section-title section-title">
                        <span>Tools</span>
                        <h2>Technologies Used</h2>
                        <div className="bar"></div>
                    </div>
                    {/* <div className="partner-slides owl-carousel owl-theme"> */}
                    <OwlCarousel 
                        className="partner-slides owl-carousel owl-theme"
                        {...options}
                    >
                        <div className="item">
                            <Link to="#">
                                <img src={angular} alt="Partner" />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <img src={react} alt="Partner" />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <img src={node} alt="Partner" />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <img src={html} alt="Partner" />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <img src={sass} alt="Partner" />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <img src={git} alt="Partner" />
                            </Link>
                        </div>
                        </OwlCarousel>
                    {/* </div> */}
                </div>
            </div>
        );
    }
}
 
export default PartnerLogo;
