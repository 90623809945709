import React from 'react';
import { Link } from 'react-router-dom';
import teams from '../../assets/data/team.json';

class TeamMember extends React.Component {
    render() {
        let parsedTeam = [];
        for (const team of teams) {
            if (parsedTeam.length > this.props.limit) {
                break;
            }
            parsedTeam.push(
                <div className="single-team" key={team.name}>
                    <ul className="team-social">
                        {
                            team.facebook ?
                                (<li><a href={team.facebook} target="_blank"><i className="flaticon-logo"></i></a></li>)
                                : null
                        }
                        {
                            team.twitter ?
                                (<li><a href={team.twitter} target="_blank"><i className="flaticon-twitter"></i></a></li>)
                                : null
                        }
                        {
                            team.linkedIn ?
                                (<li><a href={team.linkedIn} target="_blank"><i className="flaticon-linkedin"></i></a></li>)
                                : null
                        }
                        {
                            team.instagram ?
                                (<li><a href={team.instagram} target="_blank"><i className="flaticon-logo-1"></i></a></li>)
                                : null
                        }
                        {
                            team.github ?
                                (<li><a href={team.github} target="_blank"><i className="githubIcon"></i></a></li>)
                                : null
                        }
                    </ul>

                    <img src={require('../../assets/images/team/' + team.image)} alt="team" />

                    <div className="team-content">
                        <h3>{team.name}</h3>
                        <span>{team.designation}</span>
                    </div>
                </div>
            );
        }
        return parsedTeam;
    }
}

export default TeamMember;
