import React from 'react';
import { Link } from 'react-router-dom';
import projects from '../../assets/data/projects.json';

class TeamMember extends React.Component {
    render(){
        return projects.map((project, i) => {
            return (
                <div className="single-project" key={i}>
                    {/*<Link to={'/project-details/' + project.name} className="project-img">*/}
                    <Link to='' className="project-img">
                        <img src={require('../../assets/images/portfolio/' + project.image)} alt="Project" />
                    </Link>

                    <div className="project-content">
                        {
                            project.link ? <h3><Link to={project.link}>{project.name}</Link></h3> : <h3>{project.name}</h3>
                        }
                        <ul>
                        {
                            project.technologies.map((technology, i) => {
                                return <li key={i}>{technology.name}</li>
                            })
                        }
                        </ul>
                    </div>
                </div>
            );
        });
    }
}

export default TeamMember;
