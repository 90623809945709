import React from 'react';
import { Link } from 'react-router-dom';

class ProcessContent extends React.Component {
  render(){
      return (
        <section id="process" className="process-area uk-services uk-section">
            <div className="uk-container">
                <div className="uk-section-title section-title">
                    <span>How We Do</span>
                    <h2>Our Process</h2>
                    <div className="bar"></div>
                </div>
                <center>
                  {/* <p>“WE MAKE OUR CUSTOMERS HAPPY”</p>
                  <p>Curabitur eget nulla ut neque aliquam dictum. Nam sollicitudin leo dui, non malesuada felis aliquam non. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus finibus tempor neque vel scelerisque. Cras nec ex ut eleifend mollis ut a nibh. Vivamus commodo est sit amet ultricies.</p> */}
                  <div className="dashed-line" />
                  <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s uk-flex-center uk-position-relative">
                      <div className="process-item">
                        <div>
                          <img src={require('../../assets/images/process/research.png')} />
                          <h4>Research</h4>
                        </div>
                        We'll listen, offer advice, and present solutions which will help achieve your goals.
                      </div>
                      <div className="process-item">
                        <div>
                          <img src={require('../../assets/images/process/concept.png')} />
                          <h4>Concept</h4>
                        </div>
                        Stategise, plan, and execute. We create concepts that challange your ideas and get you thining differently. We then work with you to refine them, leaving you grinning from ear-to-ear.
                      </div>
                      <div className="process-item">
                        <div>
                          <img src={require('../../assets/images/process/develop.png')} />
                          <h4>Develop</h4>
                        </div>
                        From beautiful brands to exciting websites, we craft our designs with flair, care and attention to detail. We've listened, we've planned, now we make those ideas into a reality.
                      </div>
                      <div className="process-item">
                        <div>
                          <img src={require('../../assets/images/process/deliver.png')} />
                          <h4>Deliver</h4>
                        </div>
                        In pixels or on papper, our projects are delivered with the minimum of fuss using a finely tuned process. We keep you posted at every stage, giving you the confidence that we'll deliver your projects on time, every time.
                      </div>
                  </div>
                  {/* <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s uk-flex-center">
                    <p>Aliquam fermentum massa ac est sollicitudin, at ultricies ligula tristique. Cras finibus, nulla ac convallis feugiat, nisl nisl lobortis est, eget auctor velit magna vel nunc. Donec nec eros rhoncus.</p>
                  </div> */}
                </center>
            </div>
        </section>
      )
  }
}

export default ProcessContent;
