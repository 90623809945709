import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../assets/images/logo2.png';
import footerShapeOne from '../../assets/images/footer-shape1.png';
import footerShapeTwo from '../../assets/images/footer-shape2.png';
import GoTop from './GoTop';
import githubIcon from '../../assets/images/technologies/github.svg';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer-area uk-dark uk-footer">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
                        <div className="item">
                            <div className="single-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={logo} alt="logo"/>
                                    </Link>
                                </div>

                                {/*<p>Lorem ipsum dolor consectetur adipiscing elit, eiusmod tempor ut labore et dolore magna aliqua.</p>*/}
                            </div>
                        </div>

                        <div className="item">
                        </div>
                        <div className="item">
                            <div className="single-footer-widget">
                                <h3>India</h3>
                                <div className="bar"></div>
                                <div className="location">
                                    <p>3rd Floor, 10, Satya Niketan Rd,<br/> Moti Bagh II, Satya Niketan,<br/> South
                                        Moti Bagh <br/> New Delhi, Delhi 110021</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-footer-widget">
                                <h3>Contact</h3>
                                <div className="bar"></div>

                                <ul className="contact-info">
                                    <li><a href="mailto:info@luezoid.com">info@luezoid.com</a></li>
                                    <li><a href="tel:+919666718620">+91 96667 18620</a></li>
                                    <li><a href="tel:+919811070940">+91 98110 70940</a></li>
                                </ul>
                                <ul className="social">
                                    <li>
                                        <a href="https://www.facebook.com/luezoidtechnologies" rel="noopener noreferrer"
                                           target="_blank">
                                            <i className="flaticon-logo"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/luezoidtech" rel="noopener noreferrer"
                                           target="_blank">
                                            <i className="flaticon-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/in/luezoid-technologies-250597174/"
                                           rel="noopener noreferrer" target="_blank">
                                            <i className="flaticon-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://github.com/luezoidtechnologies" rel="noopener noreferrer"
                                           target="_blank">
                                            <i className="githubIcon"></i>
                                            {/*<img src={githubIcon} alt="github icon"/>*/}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="copyright-area">
                        <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                            <div className="item">
                                <p>© 2021 Luezoid. All Rights Reserved</p>
                            </div>

                            <div className="item">
                                <ul>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                    <li><Link to="#">Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <GoTop scrollStepInPx="50" delayInMs="16.66"/>
                    </div>
                </div>

                {/*<div className="br-line"></div>*/}
                <div className="footer-shape1">
                    <img src={footerShapeOne} alt="shape"/>
                </div>
                <div className="footer-shape2">
                    <img src={footerShapeTwo} alt="shape"/>
                </div>
            </footer>
        );
    }
}

export default Footer;
