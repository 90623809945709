import React from 'react'
 
const SeperatedBar = () => {
    return (
        <div className="separate uk-dark">
            <div className="br-line"></div>
        </div>
    );
};
 
export default SeperatedBar;