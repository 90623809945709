import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import ProjectCard from "../Common/ProjectCard";

const options = {
    loop: true,
    nav: false,
    dots: true,
    smartSpeed: 2000,
    margin: 30,
    autoplayHoverPause: true,
    autoplay: true,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2
        },
        786: {
            items: 2
        },
        1200: {
            items: 4
        }
    }
}

class Project extends React.Component {
    render() {
        return (
            <section id="project" className="project-area  uk-project uk-section">
                <div className="uk-container">
                    <div className="uk-section-title section-title">
                        <span>Our Completed Projects</span>
                        <h2>Recent Projects</h2>
                        <div className="bar"></div>

                        {/*<Link to="projects" className="uk-button uk-button-default">All Projects</Link>*/}
                    </div>
                </div>

                <OwlCarousel
                    className="project-slides owl-carousel owl-theme"
                    {...options}
                >
                    <ProjectCard/>

                    {/*<div className="single-project">
                        <Link to="#" className="project-img">
                            <img src={project3} alt="project" />
                        </Link>

                        <div className="project-content">
                            <h3><Link to="#">L1 App</Link></h3>
                            <ul>
                                <li><Link to="#">Mobile</Link></li>
                            </ul>
                        </div>
                    </div>*/}
                </OwlCarousel>
            </section>
        );
    }
}

export default Project;
