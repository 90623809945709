import React from 'react';
import TeamMember from '../Common/TeamMember';

class TeamBody extends React.Component {
    render(){
        return (
            <section className="team-area uk-team uk-section">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                        <TeamMember/>
                    </div>
                </div>
            </section>
        );
    }
}

export default TeamBody;
