import React from 'react';
import { Link } from 'react-router-dom';
import TeamMember from '../Common/TeamMember';
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: false,
    dots: true,
    smartSpeed: 2000,
    margin: 15,
    autoplayHoverPause: true,
    autoplay: true,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2
        },
        786: {
            items: 2
        },
        1200: {
            items: 5
        }
    }
}

class Team extends React.Component {
    render(){
        return (
            <section id="team" className="team-area  uk-team uk-section">
                <div className="uk-container">
                    <div className="uk-section-title section-title">
                        <span>Meet Our Experts</span>
                        <h2>Our Team</h2>
                        <div className="bar"></div>

                        <Link to="/team" className="uk-button uk-button-default">View All</Link>
                    </div>
                </div>

                <OwlCarousel
                    className="team-slides owl-carousel owl-theme"
                    {...options}
                >
                    <TeamMember limit={4}/>
                </OwlCarousel>
            </section>
        );
    }
}

export default Team;
