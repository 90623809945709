import React from 'react'
import about1 from "../../assets/images/about1.png";
 
class AboutContent extends React.Component {
    render(){
        return (
            <section className="uk-about about-area uk-section">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s uk-flex-middle">
                        <div className="item">
                            <div className="about-content">
                                <div className="uk-section-title section-title">
                                    <span>About Us</span>
                                    <h2>Creative partners and Extraordinary Changemakers.</h2>
                                    <div className="bar"></div>
                                </div>

                                <div className="about-text">
                                    <div className="icon">
                                        <i className="flaticon-quality"></i>
                                    </div>
                                    <p>We own, manage and run outsourcing company also act as consultants, advisors, developers and traders in computer programming, system development, system design, system architecture, software designing, computer-aided designs, data compilation and statistical analysis and carry on the business of traders, developers, assemblers, repairs, importers, exporters of software packages, computer systems, computer peripherals, computer parts, computer consumables and electronic communication systems, data publishing and processing systems and other industrial automation systems and gadgets.</p>
                                    <p>Together, we solve complex challenges that shape the ways we'll live and work. We're a team of creators, thinkers, explores and developers. We approach work and play with curiosity and experimentation, using what we learn to create meaningful digital life changing platforms.</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div>
                                <img src={about1} className="about-img1 img-fluid" alt="about-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default AboutContent;
