import React from 'react';
import lax from 'lax.js';
import signature from '../../assets/images/signature.png';
import about1 from '../../assets/images/about1.png';
import about2 from '../../assets/images/about2.jpg';
import one from '../../assets/images/1.png';
import LaxButton from '../Common/LaxButton';

class About extends React.Component {
    constructor(props) {
        super(props)
        lax.setup()

        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)

        lax.update(window.scrollY)
    }
    render(){
        return (
            <section id="about" className="uk-about  about-area uk-section">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s uk-flex-middle">
                        <div className="item">
                            <div className="about-content">
                                <div className="uk-section-title section-title">
                                    <span>About Us</span>
                                    <h2>Creative partners and Extraordinary Changemakers.</h2>
                                    <div className="bar"></div>
                                </div>

                                <div className="about-text">
                                    <div className="icon">
                                        <i className="flaticon-quality"></i>
                                    </div>
                                    {/*<h3>Best Digital Agency in the World</h3>*/}
                                    <p>We own, manage and run outsourcing company also act as consultants, advisors, developers and traders in computer programming, system development, system design, system architecture, software designing, computer-aided designs, data compilation and statistical analysis and carry on the business of traders, developers, assemblers, repairs, importers, exporters of software packages, computer systems, computer peripherals, computer parts, computer consumables and electronic communication systems, data publishing and processing systems and other industrial automation systems and gadgets.</p>
                                    <p>Together, we solve complex challenges that shape the ways we'll live and work. We're a team of creators, thinkers, explores and developers. We approach work and play with curiosity and experimentation, using what we learn to create meaningful digital life changing platforms.</p>

                                    {/*<div className="signature">
                                        <img src={signature} alt="signature" />
                                    </div>*/}
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            {/*<div className="about-img">*/}
                            <div>
                                <img src={about1} className="about-img1 img-fluid" alt="about-img" />
                                {/*<img src={about2} className="about-img2" alt="about-img" />*/}
                                {/*<img src={one} className="shape-img" alt="shape" />*/}
                                {/*<div style={{textAlign: 'right'}}>
                                    <LaxButton />
                                </div>*/}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;
