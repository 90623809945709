import React from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from "react-anchor-link-smooth-scroll";
 
class NavigationFour extends React.Component {
    render(){
        let pathName = window.location.pathname;
        return (
            <React.Fragment>
                <div id="offcanvas-flip" className="mobile-navbar uk-mobile-navbar" uk-offcanvas="flip: true; overlay: true">
                    <div className="uk-offcanvas-bar">
                
                        <button className="uk-offcanvas-close" type="button" uk-close="true"></button>

                        <nav className="uk-navbar-container">
                            <ul className="uk-navbar-nav">
                                <li className="uk-active">
                                    <AnchorLink offset={() => 100} href="#home">Home</AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink offset={() => 100} href="#about">About</AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink offset={() => 100} href="#services">Services</AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink offset={() => 100} href="#project">Project</AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink offset={() => 100} href="#team">Team</AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink offset={() => 100} href="#contact">Contact</AnchorLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {/* End Mobile Navbar */}

                {/* Start Navbar Area */}
                <header className="header-area uk-dark" data-uk-sticky="top: 0; animation: uk-animation-slide-top;">
                    <div className="uk-container">
                        <div className="uk-navbar">
                            <div className="logo uk-navbar-left">
                                <a href="/">
                                    <img src={require("../../assets/images/logo2.png")} alt="logo" />
                                </a>
                            </div>

                            <div className="uk-navbar-toggle" id="navbar-toggle" uk-toggle="target: #offcanvas-flip">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <div className="navbar uk-navbar-right">
                                <nav className="uk-navbar-container">
                                    <ul className="uk-navbar-nav">
                                        <li className="uk-active">
                                            <AnchorLink offset={() => 100} href="#home">Home</AnchorLink>
                                        </li>
                                        <li>
                                            <Link offset={() => 100} to="/#about">About</Link>
                                        </li>
                                        <li>
                                            <AnchorLink offset={() => 100} href="#services">Services</AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink offset={() => 100} href="#project">Project</AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink offset={() => 100} href="#team">Team</AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink offset={() => 100} href="#contact">Contact</AnchorLink>
                                        </li>
                                    </ul>
                                </nav>

                                {/*<div className="lang">
                                    <form>
                                        <div>
                                            <select>
                                                <option value="eng">En</option>
                                                <option value="ge">Ge</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}
 
export default NavigationFour;
